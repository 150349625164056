import { createAction, props } from '@ngrx/store';
import { BasicResponse, ResponseWrapper } from 'common';
import { OlyticsBehavior, OlyticsBehaviorResponse, OlyticsDomainsResponse, OlyticsImplementationData, OlyticsProfiles, OlyticsTagsResponse, OlyticsTitleSourceResponse, PageTypeExclusionsRequest, PageTypeExclusionsResponse, OlyticsTitleSourceRequest } from './olytics.interface';

export enum CustomerDataPlatformOlyticsInnerActionTypes {
    GetOlyticsImplementationDataSuccess = '[Olytics] Get Olytics Implementation Data Success',
    GetOlyticsImplementationDataError = '[Olytics] Get Olytics Implementation Data Error',
    GetOlyticsBehaviorsSuccess = '[Olytics] Get Olytics Behaviors Success',
    GetOlyticsBehaviorsError = '[Olytics] Get Olytics Behaviors Error',
    GetOlyticsDomainsSuccess = '[Olytics] Get Olytics Domains Success',
    GetOlyticsDomainsError = '[Olytics] Get Olytics Domains Error',
    SaveOlyticsBehaviorSuccess = '[Olytics] Save Olytics Behavior Success',
    SaveOlyticsBehaviorError = '[Olytics] Save Olytics Behavior Error',
    SaveOlyticsDomainSuccess = '[Olytics] Save Olytics Domain Success',
    SaveOlyticsDomainError = '[Olytics] Save Olytics Domain Error',
    DeleteOlyticsBehaviorSuccess = '[Olytics] Delete Olytics Behavior Success',
    DeleteOlyticsBehaviorError = '[Olytics] Delete Olytics Behavior Error',
    DeleteOlyticsDomainSuccess = '[Olytics] Delete Olytics Domain Success',
    DeleteOlyticsDomainError = '[Olytics] Delete Olytics Domain Error',
    GetOlyticsProfilesSuccess = '[Olytics] Get Olytics Profiles Success',
    GetOlyticsProfilesError = '[Olytics] Get Olytics Profiles Error',
    SyncDomainsAndBehaviorsSuccess = '[Olytics] Sync Domains And Behaviors Success',
    SyncDomainsAndBehaviorsError = '[Olytics] Sync Domains And Behaviors Error',
    UploadDomainsAndBehaviorsSuccess = '[Olytics] Upload Domains And Behaviors Success',
    UploadDomainsAndBehaviorsError = '[Olytics] Upload Domains And Behaviors Error',
    GetOlyticsTagsSuccess = '[Olytics] Get Olytics Tags Success',
    GetOlyticsTagsError = '[Olytics] Get Olytics Tags Error',
    SyncOlyticsTagsSuccess = '[Olytics] Sync Olytics Tags Success',
    SyncOlyticsTagsError = '[Olytics] Sync Olytics Tags Error',
    SaveOlyticsTagSuccess = '[Olytics] Save Olytics Tag Success',
    SaveOlyticsTagError = '[Olytics] Save Olytics Tag Error',
    GetContentRecommendationBehaviorsSuccess = '[Olytics] Get Content Recommendation Behaviors Success',
    GetContentRecommendationBehaviorsError = '[Olytics] Get Content Recommendation Behaviors Error',
    GetBehaviorPageTypeExclusionsSuccess = '[Olytics] Get Behavior Page Type Exclusions Success',
    GetBehaviorPageTypeExclusionsError = '[Olytics] Get Behavior Page Type Exclusions Error',
    SaveBehaviorPageTypeExlusionsSuccess = '[Olytics] Save Behavior Page Type Exclusions Success',
    SaveBehaviorPageTypeExlusionsError = '[Olytics] Save Behavior Page Type Exclusions Error',
    GetSelectedSpecificExclusionsSuccess = '[Olytics] Get Selected Specific Exclusions Success',
    GetSelectedSpecificExclusionsError = '[Olytics] Get Selected Specific Exclusions Error',
    GetAvailableSpecificExclusionsSuccess = '[Olytics] Get Available Specific Exclusions Success',
    GetAvailableSpecificExclusionsError = '[Olytics] Get Available Specific Exclusions Error',
    SaveSpecificExclusionsSuccess = '[Olytics] Save Specific Exclusions Success',
    SaveSpecificExclusionsError = '[Olytics] Save Specific Exclusions Error',
    GetUrlStringRestrictionSuccess = '[Olytics] Get Url String Exclusions Success',
    GetUrlStringRestrictionError = '[Olytics] Get Url String Exclusions Error',
    SaveUrlStringRestrictionsSuccess = '[Olytics] Save Url String Exclusions Success',
    SaveUrlStringRestrictionsError = '[Olytics] Save Url String Exclusions Error',
    GetTitleSourceSuccess = '[Olytics] Get Olytics Title Source Success',
    GetTitleSourceError = '[Olytics] Get Olytics Title Source Error',
    SaveTitleSourceSuccess = '[Olytics] Save Olytics Title Source Success',
    SaveTitleSourceError = '[Olytics] Save Olytics Title Source Error',
}

export enum CustomerDataPlatformOlyticsHttpActionTypes {
    GetOlyticsImplementationData = '[Olytics] Get Olytics Implementation Data',
    GetOlyticsBehaviors = '[Olytics] Get Olytics Behaviors',
    GetOlyticsDomains = '[Olytics] Get Olytics Domains',
    SaveOlyticsBehavior = '[Olytics] Save Olytics Behavior',
    SaveOlyticsDomain = '[Olytics] Save Olytics Domain',
    DeleteOlyticsBehavior = '[Olytics] Delete Olytics Behavior',
    DeleteOlyticsDomain = '[Olytics] Delete Olytics Domain',
    GetOlyticsProfiles = '[Olytics] Get Olytics Profiles',
    SyncDomainsAndBehaviors = '[Olytics] Sync Domains And Behaviors',
    UploadDomainsAndBehaviors = '[Olytics] Upload Domains And Behaviors',
    GetOlyticsTags = '[Olytics] Get Olytics Tags',
    SyncOlyticsTags = '[Olytics] Sync Olytics Tags',
    SaveOlyticsTag = '[Olytics] Save Olytics Tag',
    GetContentRecommendationBehaviors = '[Olytics] Get Content Recommendation Behaviors',
    GetBehaviorPageTypeExclusions = '[Olytics] Get Behavior Page Type Exclusions',
    SaveBehaviorPageTypeExlusions = '[Olytics] Save Behavior Page Type Exclusions',
    GetSelectedSpecificExclusions = '[Olytics] Get Selected Specific Exclusions',
    GetAvailableSpecificExclusions = '[Olytics] Get Available Specific Exclusions',
    SaveSpecificExclusions = '[Olytics] Save Specific Exclusions',
    GetUrlStringRestrictions = '[Olytics] Get Url String Restrictions',
    SaveUrlStringRestrictions = '[Olytics] Save Url String Restrictions',
    GetTitleSource = '[Olytics] Get Olytics Title Source',
    SaveTitleSource = '[Olytics] Save Olytics Title Source',
}

// HTTP OLYTICS ACTIONS
export const GetOlyticsImplementationData = createAction(CustomerDataPlatformOlyticsHttpActionTypes.GetOlyticsImplementationData);
export const GetOlyticsBehaviors = createAction(CustomerDataPlatformOlyticsHttpActionTypes.GetOlyticsBehaviors, props<{ payload: {numResults?: number, offset?: number, order?: string, sortBy?: string, name?: string, createdData?: string} }>());
export const GetOlyticsDomains = createAction(CustomerDataPlatformOlyticsHttpActionTypes.GetOlyticsDomains, props<{ payload: {numResults?: number, offset?: number, order?: string, sortBy?: string, name?: string, createdData?: string} }>());
export const SaveOlyticsBehavior = createAction(CustomerDataPlatformOlyticsHttpActionTypes.SaveOlyticsBehavior, props<{ payload: {behaviorId: number, behaviorName: string, contentRecommendationEnabled?: boolean,contentRecommendationTypeCode?: number, profiles?: Array<number>} }>());
export const SaveOlyticsDomain = createAction(CustomerDataPlatformOlyticsHttpActionTypes.SaveOlyticsDomain, props<{ payload: {domainId: number, domainName: string} }>());
export const GetOlyticsProfiles = createAction(CustomerDataPlatformOlyticsHttpActionTypes.GetOlyticsProfiles);
export const DeleteOlyticsBehavior = createAction(CustomerDataPlatformOlyticsHttpActionTypes.DeleteOlyticsBehavior, props<{ payload: {behaviorId: number} }>());
export const DeleteOlyticsDomain = createAction(CustomerDataPlatformOlyticsHttpActionTypes.DeleteOlyticsDomain, props<{ payload: {domainId: number} }>());
export const SyncDomainsAndBehaviors = createAction(CustomerDataPlatformOlyticsHttpActionTypes.SyncDomainsAndBehaviors);
export const UploadDomainsAndBehaviors = createAction(CustomerDataPlatformOlyticsHttpActionTypes.UploadDomainsAndBehaviors, props<{ payload: {file: File, uploadModalId: string} }>());
export const GetOlyticsTags = createAction(CustomerDataPlatformOlyticsHttpActionTypes.GetOlyticsTags, props<{ payload: {numResults?: number, offset?: number, order?: string, sortBy?: string, name?: string, createdData?: string} }>());
export const SyncOlyticsTags = createAction(CustomerDataPlatformOlyticsHttpActionTypes.SyncOlyticsTags);
export const SaveOlyticsTag = createAction(CustomerDataPlatformOlyticsHttpActionTypes.SaveOlyticsTag, props<{ payload: {tagId: number, tagName: string, tagIdentifier: string} }>());
export const GetContentRecommendationBehaviors = createAction(CustomerDataPlatformOlyticsHttpActionTypes.GetContentRecommendationBehaviors);
export const GetBehaviorPageTypeExclusions = createAction(CustomerDataPlatformOlyticsHttpActionTypes.GetBehaviorPageTypeExclusions, props<{ payload: {behaviorId: number} }>());
export const SaveBehaviorPageTypeExlusions = createAction(CustomerDataPlatformOlyticsHttpActionTypes.SaveBehaviorPageTypeExlusions, props<{ payload: PageTypeExclusionsRequest }>());
export const GetSelectedSpecificExclusions = createAction(CustomerDataPlatformOlyticsHttpActionTypes.GetSelectedSpecificExclusions, props<{ payload: {behaviorId: number, numResults?: number, offset?: number, order?: string, sortBy?: string} }>());
export const GetAvailableSpecificExclusions = createAction(CustomerDataPlatformOlyticsHttpActionTypes.GetAvailableSpecificExclusions, props<{ payload: {behaviorId: number, searchText?: string, numResults?: number, offset?: number, order?: string, sortBy?: string} }>());
export const SaveSpecificExclusions = createAction(CustomerDataPlatformOlyticsHttpActionTypes.SaveSpecificExclusions, props<{ payload: {behaviorId: number, pageExclusions: Array<number>} }>());
export const GetUrlStringRestrictions = createAction(CustomerDataPlatformOlyticsHttpActionTypes.GetUrlStringRestrictions, props<{ payload: {behaviorId: number}}>());
export const SaveUrlStringRestrictions = createAction(CustomerDataPlatformOlyticsHttpActionTypes.SaveUrlStringRestrictions, props<{ payload: {behaviorId: number, stringExclusions: Array<string>, stringInclusions: Array<string>} }>());
export const GetTitleSource = createAction(CustomerDataPlatformOlyticsHttpActionTypes.GetTitleSource, props<{ payload: {behaviorId: number}}>());
export const SaveTitleSource = createAction(CustomerDataPlatformOlyticsHttpActionTypes.SaveTitleSource, props<{ payload: OlyticsTitleSourceRequest }>());

// INNER OLYTICS ACTIONS
export const GetOlyticsImplementationDataSuccess = createAction(CustomerDataPlatformOlyticsInnerActionTypes.GetOlyticsImplementationDataSuccess, props<{ payload: ResponseWrapper<OlyticsImplementationData> }>());
export const GetOlyticsImplementationDataError = createAction(CustomerDataPlatformOlyticsInnerActionTypes.GetOlyticsImplementationDataError, props<{ error: BasicResponse }>());
export const GetOlyticsBehaviorsSuccess = createAction(CustomerDataPlatformOlyticsInnerActionTypes.GetOlyticsBehaviorsSuccess, props<{ payload: ResponseWrapper<OlyticsBehaviorResponse> }>());
export const GetOlyticsBehaviorsError = createAction(CustomerDataPlatformOlyticsInnerActionTypes.GetOlyticsBehaviorsError, props<{ error: BasicResponse }>());
export const GetOlyticsDomainsSuccess = createAction(CustomerDataPlatformOlyticsInnerActionTypes.GetOlyticsDomainsSuccess, props<{ payload: ResponseWrapper<OlyticsDomainsResponse> }>());
export const GetOlyticsDomainsError = createAction(CustomerDataPlatformOlyticsInnerActionTypes.GetOlyticsDomainsError, props<{ error: BasicResponse }>());
export const SaveOlyticsBehaviorSuccess = createAction(CustomerDataPlatformOlyticsInnerActionTypes.SaveOlyticsBehaviorSuccess);
export const SaveOlyticsBehaviorError = createAction(CustomerDataPlatformOlyticsInnerActionTypes.SaveOlyticsBehaviorError, props<{ error: BasicResponse }>());
export const GetOlyticsProfilesSuccess = createAction(CustomerDataPlatformOlyticsInnerActionTypes.GetOlyticsProfilesSuccess, props<{ payload: ResponseWrapper<Array<OlyticsProfiles>>}>());
export const GetOlyticsProfilesError = createAction(CustomerDataPlatformOlyticsInnerActionTypes.GetOlyticsProfilesError, props<{ error: BasicResponse }>());
export const SaveOlyticsDomainSuccess = createAction(CustomerDataPlatformOlyticsInnerActionTypes.SaveOlyticsDomainSuccess);
export const SaveOlyticsDomainError = createAction(CustomerDataPlatformOlyticsInnerActionTypes.SaveOlyticsDomainError, props<{ error: BasicResponse }>());
export const DeleteOlyticsBehaviorSuccess = createAction(CustomerDataPlatformOlyticsInnerActionTypes.DeleteOlyticsBehaviorSuccess);
export const DeleteOlyticsBehaviorError = createAction(CustomerDataPlatformOlyticsInnerActionTypes.DeleteOlyticsBehaviorError, props<{ error: BasicResponse }>());
export const DeleteOlyticsDomainSuccess = createAction(CustomerDataPlatformOlyticsInnerActionTypes.DeleteOlyticsDomainSuccess);
export const DeleteOlyticsDomainError = createAction(CustomerDataPlatformOlyticsInnerActionTypes.DeleteOlyticsDomainError, props<{ error: BasicResponse }>());
export const SyncDomainsAndBehaviorsSuccess = createAction(CustomerDataPlatformOlyticsInnerActionTypes.SyncDomainsAndBehaviorsSuccess, props<{ payload: BasicResponse }>());
export const SyncDomainsAndBehaviorsError = createAction(CustomerDataPlatformOlyticsInnerActionTypes.SyncDomainsAndBehaviorsError, props<{ error: BasicResponse }>());
export const UploadDomainsAndBehaviorsSuccess = createAction(CustomerDataPlatformOlyticsInnerActionTypes.UploadDomainsAndBehaviorsSuccess, props<{ payload: BasicResponse }>());
export const UploadDomainsAndBehaviorsError = createAction(CustomerDataPlatformOlyticsInnerActionTypes.UploadDomainsAndBehaviorsError, props<{ error: BasicResponse }>());
export const GetOlyticsTagsSuccess = createAction(CustomerDataPlatformOlyticsInnerActionTypes.GetOlyticsTagsSuccess, props<{ payload: ResponseWrapper<OlyticsTagsResponse>}>());
export const GetOlyticsTagsError = createAction(CustomerDataPlatformOlyticsInnerActionTypes.GetOlyticsTagsError, props<{ error: BasicResponse }>());
export const SyncOlyticsTagsSuccess = createAction(CustomerDataPlatformOlyticsInnerActionTypes.SyncOlyticsTagsSuccess, props<{ payload: BasicResponse }>());
export const SyncOlyticsTagsError = createAction(CustomerDataPlatformOlyticsInnerActionTypes.SyncOlyticsTagsError, props<{ error: BasicResponse }>());
export const SaveOlyticsTagSuccess = createAction(CustomerDataPlatformOlyticsInnerActionTypes.SaveOlyticsTagSuccess);
export const SaveOlyticsTagError = createAction(CustomerDataPlatformOlyticsInnerActionTypes.SaveOlyticsTagError, props<{ error: BasicResponse }>());
export const GetContentRecommendationBehaviorsSuccess = createAction(CustomerDataPlatformOlyticsInnerActionTypes.GetContentRecommendationBehaviorsSuccess, props<{ payload: ResponseWrapper<Array<OlyticsBehavior>> }>());
export const GetContentRecommendationBehaviorsError = createAction(CustomerDataPlatformOlyticsInnerActionTypes.GetContentRecommendationBehaviorsError, props<{ error: BasicResponse }>());
export const GetBehaviorPageTypeExclusionsSuccess = createAction(CustomerDataPlatformOlyticsInnerActionTypes.GetBehaviorPageTypeExclusionsSuccess, props<{ payload: ResponseWrapper<PageTypeExclusionsResponse> }>());
export const GetBehaviorPageTypeExclusionsError = createAction(CustomerDataPlatformOlyticsInnerActionTypes.GetBehaviorPageTypeExclusionsError, props<{ error: BasicResponse }>());
export const SaveBehaviorPageTypeExlusionsSuccess = createAction(CustomerDataPlatformOlyticsInnerActionTypes.SaveBehaviorPageTypeExlusionsSuccess);
export const SaveBehaviorPageTypeExlusionsError = createAction(CustomerDataPlatformOlyticsInnerActionTypes.SaveBehaviorPageTypeExlusionsError, props<{ error: BasicResponse }>());
export const GetSelectedSpecificExclusionsSuccess = createAction(CustomerDataPlatformOlyticsInnerActionTypes.GetSelectedSpecificExclusionsSuccess, props<{ payload: ResponseWrapper<{totalResultCount: number, items: Array<{code: number, name: string}>}> }>());
export const GetSelectedSpecificExclusionsError = createAction(CustomerDataPlatformOlyticsInnerActionTypes.GetSelectedSpecificExclusionsError, props<{ error: BasicResponse }>());
export const GetAvailableSpecificExclusionsSuccess = createAction(CustomerDataPlatformOlyticsInnerActionTypes.GetAvailableSpecificExclusionsSuccess, props<{ payload: ResponseWrapper<{totalResultCount: number, items: Array<{code: number, name: string}>}> }>());
export const GetAvailableSpecificExclusionsError = createAction(CustomerDataPlatformOlyticsInnerActionTypes.GetAvailableSpecificExclusionsError, props<{ error: BasicResponse }>());
export const SaveSpecificExclusionsSuccess = createAction(CustomerDataPlatformOlyticsInnerActionTypes.SaveSpecificExclusionsSuccess);
export const SaveSpecificExclusionsError = createAction(CustomerDataPlatformOlyticsInnerActionTypes.SaveSpecificExclusionsError, props<{ error: BasicResponse }>());
export const GetUrlStringRestrictionsSuccess = createAction(CustomerDataPlatformOlyticsInnerActionTypes.GetUrlStringRestrictionSuccess, props<{ payload: ResponseWrapper<Array<string>> }>());
export const GetUrlStringRestrictionsError = createAction(CustomerDataPlatformOlyticsInnerActionTypes.GetUrlStringRestrictionError, props<{ error: BasicResponse }>());
export const SaveUrlStringRestrictionsSuccess = createAction(CustomerDataPlatformOlyticsInnerActionTypes.SaveUrlStringRestrictionsSuccess);
export const SaveUrlStringRestrictionsError = createAction(CustomerDataPlatformOlyticsInnerActionTypes.SaveUrlStringRestrictionsError, props<{ error: BasicResponse }>());
export const GetTitleSourceSuccess = createAction(CustomerDataPlatformOlyticsInnerActionTypes.GetTitleSourceSuccess, props<{ payload: ResponseWrapper<OlyticsTitleSourceResponse>}>());
export const GetTitleSourceError = createAction(CustomerDataPlatformOlyticsInnerActionTypes.GetTitleSourceError, props<{ error: BasicResponse }>());
export const SaveTitleSourceSuccess = createAction(CustomerDataPlatformOlyticsInnerActionTypes.SaveTitleSourceSuccess);
export const SaveTitleSourceError = createAction(CustomerDataPlatformOlyticsInnerActionTypes.SaveTitleSourceError, props<{ error: BasicResponse }>());
